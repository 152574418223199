import styled from 'styled-components'

const width = 50
const heightInPercent = 0.15
const translateHeight = (width * (1 + heightInPercent)) / 4

const HBar = styled.div`
  width: ${width}px;
  height: ${width * heightInPercent}px;
  background-color: white;
  margin: ${(width * (1 - heightInPercent * 3)) / 4}px auto;
`

const HBar1 = styled(HBar)`
  transform: ${props =>
    `translateY(${props.isNavOpen ? translateHeight : 0}px)
rotate(${props.isNavOpen ? 45 : 0}deg)`};
  transition: transform 0.25s ease-in-out;
`

const HBar3 = styled(HBar)`
  transform: ${props =>
    `translateY(${props.isNavOpen ? -translateHeight : 0}px)
rotate(${props.isNavOpen ? -45 : 0}deg)`};
  transition: transform 0.25s ease-in-out;
`

const NavWrapper = styled.div`
  cursor: pointer;
  display: none;

  @media (min-width: 600px), (orientation: landscape) {
    display: block;
    margin: 20px;
    width: ${width}px;
    height: ${width}px;
    text-align: center;
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
  }
`

export { HBar, HBar1, HBar3, NavWrapper }

import styled, { keyframes } from 'styled-components'

// first-time loading animations
const slideIn = keyframes`
from {
    transform: translateX(-50vw); }
  to {
    transform: translateX(0); }
}
`

const slideDown = keyframes`
from {
    transform: translateY(-110vh); }
  to {
   transform: translateY(0); }
`

// animated curtain
const Curtain = styled.div`
  width: 100vw;
  height: 100vh;
  animation: ${slideDown} 1s ease-in-out;
  border-radius: 0 0 40px 40px;
  box-shadow: 0 5px 15px -2px grey;
  background-color: #da3f3d;
  position: fixed;
  z-index: 2;
  left: 0;
  @media (min-width: 600px), (orientation: landscape) {
    width: 35vw;
    border-radius: 0 15px 15px 0;
    box-shadow: 5px 0 15px -2px grey;
    animation: ${slideIn} 1s ease-in-out;
  }
`

export default Curtain

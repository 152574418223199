import React, { Component } from 'react'

import ContentBlock from '../../../../../components/ContentBlock'

export default class Home extends Component {
  render() {
    return (
      <div>
        <ContentBlock>
          This is a collection of React powered common UI components. Please
          feel free to poke around and break things. Overall, Managing states of
          components in an application is made easy by React. State change
          triggers event functions that can be propagated automatically to
          children components. Redux reinforces this pattern by enforcing the
          principle of single source of truth. In the end, with the React-Redux
          model, UI management evolves to fancy manipulations of a single
          client-side data base. At the moment, I&apos;m rewriting these toy
          components with the recently introduced React hooks and experimenting
          with GraphQL.
        </ContentBlock>
      </div>
    )
  }
}

import React, { useState } from 'react'
import { Motion, spring } from 'react-motion'
import FadeInOut from '../Utils/FadeInOut'

import Button from '../Utils/Button'
import { BG, ToggleBlock } from './style'

const Toggle = () => {
  const [isOpen, setIsOpen] = useState(false)

  const animatedContent = isOpen
    ? [{ key: 'FadeInOut', data: 'FadeInOut', opacity: spring(1) }]
    : null

  return (
    <div>
      <Button onClick={() => setIsOpen(!isOpen)}>Toggle</Button>
      <h3>Status: {isOpen ? 'open' : 'closed'}</h3>
      <Motion style={{ x: spring(isOpen ? 200 : 0) }}>
        {({ x }) => (
          <BG>
            <ToggleBlock
              onClick={() => setIsOpen(!isOpen)}
              style={{
                transform: `translate3d(${x}px, 0, 0)`
              }}
            />
          </BG>
        )}
      </Motion>
      <FadeInOut content={animatedContent} />
    </div>
  )
}

export default Toggle

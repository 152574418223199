import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const WhiteLink = styled(NavLink)`
  :link {
    color: white;
  }

  :hover,
  :visited:hover {
    color: white;
  }

  :visited {
    color: white;
  }
`

export default WhiteLink

import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Introduction from '../../components/Introduction'
import Nav from '../../containers/Nav'
import HomePage from '../HomePage/'
import BlogPage from '../BlogPage/'
import Post from '../../containers/Post/'
import ProjectPage from '../ProjectsPage/'
import UIApp from '../ProjectsPage/UI-Collection/'
import NotFound from '../NotFoundPage/'
import LeftPanelWrapper from '../LeftPanel'
import getViewWidth from '../../utils/getViewWidth'

import AppWrapper from './style'
import GlobalStyles from '../../global-styles'

import { AppContext } from './AppContext'

export class App extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object.isRequired
  }

  state = { shouldLoadeNav: false, isNavOpen: false, viewHeight: 0 }

  updateHeight = () => {
    this.setState({
      ...this.state,
      viewHeight: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      )
    })
  }

  componentDidMount() {
    this.updateHeight()
    // to correctly mesure the screean view height,
    // listen for  orientation change on mobile devices
    // and resize event on desktop devices
    // reference: https://stackoverflow.com/questions/11444838/
    // https://stackoverflow.com/questions/39384154
    window.addEventListener('orientationchange', () => {
      this.updateHeight()
    })

    if (this.getWindowWidth() > 600) {
      window.addEventListener('resize', () => {
        this.updateHeight()
      })
    }
    // track scroll position and determine when to load nav
    window.addEventListener('scroll', this.handleScroll)

    // though the scroll event sets the state when user scrolls,
    // the purecomponent ensures this component only updates when
    // shouldLoadeNav changes from false to true
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.updateHeight())
    if (this.getWindowWidth() > 600) {
      window.removeEventListener('resize', () => {
        this.updateHeight()
      })
    }
    window.removeEventListener('scroll', this.handleScroll)
  }

  getWindowWidth = () =>
    Math.max(window.innerWidth || 0, document.documentElement.clientWidth)
  handleScroll = () => {
    const fadeInStartPortion = 0.4
    this.setState({
      shouldLoadeNav:
        window.scrollY > this.state.viewHeight * (1 - fadeInStartPortion)
    })
  }

  isHome = () => {
    const path = this.props.location.pathname
    return path === '/'
  }

  shouldLoadeNav = () => {
    // extra logic is handle here instead of in the event listener
    return getViewWidth() >= 600 || !this.isHome() || this.state.shouldLoadeNav
  }

  shouldLoadIntro = () => getViewWidth() >= 600 || this.isHome()

  render() {
    // Navigation loading logic:
    // 1. load nav on mobile home page when window is scrolled up
    // for a certain portion, currently 0.4. See handleScroll
    // 2. always load nav on non home page and desktop site
    // Introduction loading logic:
    // 1. load intro on the home page of mobile site
    // 2. always load intro on desktop site
    return (
      <AppWrapper>
        <GlobalStyles />
        <Helmet>
          <meta
            property="og:image"
            content="http://res.cloudinary.com/asdf123101/image/upload/v1505184204/logo-scraper.png"
          />
          <meta
            property="og:image:secure_url"
            content="https://res.cloudinary.com/asdf123101/image/upload/v1505184204/logo-scraper.png"
          />
          <title>Keyi Ni</title>
        </Helmet>
        <Nav
          viewHeight={this.state.viewHeight}
          path={this.props.location.pathname}
          willLoad={this.shouldLoadeNav()}
          isNavOpen={this.state.isNavOpen}
          toggleNav={(isNavOpen = !this.state.isNavOpen) =>
            this.setState({ ...this.state, isNavOpen })
          }
        />
        {this.shouldLoadIntro() && (
          <Introduction
            viewHeight={this.state.viewHeight}
            isNavOpen={this.state.isNavOpen}
          />
        )}
        <LeftPanelWrapper
          viewHeight={this.state.viewHeight}
          isHome={this.isHome()}
        >
          <AppContext.Provider
            value={{
              isNavOpen: this.state.isNavOpen,
              setIsNavOpen: isNavOpen => {
                this.setState({ ...this.state, isNavOpen })
              }
            }}
          >
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/blog" component={BlogPage} />
              <Route path="/blog/:postID/:postTitle" component={Post} />
              <Route exact path="/projects" component={ProjectPage} />
              <Route path="/projects/ui" component={UIApp} />
              <Route path="/:postID" component={Post} />
              <Route path="" component={NotFound} />
            </Switch>
          </AppContext.Provider>
        </LeftPanelWrapper>
      </AppWrapper>
    )
  }
}

// give the App location props using withRouter HOC
export default withRouter(App)

import styled from 'styled-components'

const Button = styled.button`
  border: 1px solid #afafaf;
  padding: 2px 5px;
  cursor: pointer;

  &:hover {
    background-color: #70c1b3;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &:active {
    position: relative;
    top: 1px;
  }

  &:focus {
    outline: none;
  }
`

export default Button

import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import qs from 'query-string'

import NotFound from '../NotFoundPage/index'
import Loader from '../../components/Loader/index'
import BlogMain from './BlogMain'
import { GET_POSTS } from './queries'

// return the post if tag is given (for now it has to be a string)
// and matches any tag in the post
const matchTag = (posts, tag) => {
  // return all posts if no query
  if (!tag) return posts
  const tagedPosts = posts.filter(post => post.tags.includes(tag))
  return tagedPosts
}

const BlogPage = () => {
  const history = useHistory()
  const location = useLocation()
  const { tag } = qs.parse(location.search)
  const limit = 5
  const offset = 0
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_POSTS, {
    variables: { limit, offset }
  })
  if (error) {
    return <NotFound code={'404'} reload={() => refetch()} />
  }
  if (loading) return <Loader />
  const tagedPosts = matchTag(data.posts, tag)
  return (
    <BlogMain
      history={history}
      tag={tag}
      tagedPosts={tagedPosts}
      handleFetchMore={() =>
        fetchMore({
          variables: {
            offset: data.posts.length,
            limit
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult || fetchMoreResult.posts.length === 0) {
              return prev
            }
            console.log('fetching new posts')
            return Object.assign({}, prev, {
              posts: [...prev.posts, ...fetchMoreResult.posts]
            })
          }
        })
      }
    />
  )
}

export default BlogPage

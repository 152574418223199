import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Title from '../../components/Title'
import EntryContent from '../../components/ContentBlock/index'
import NavButton from '../../components/NavButton/index'

export const Error = ({ msg, code, reload, is404, history }) => (
  <div>
    <Title>{`Error ${code}`}</Title>
    <hr />
    <EntryContent style={{ marginBottom: '0.5em' }}>{msg}</EntryContent>
    <NavButton
      msg={is404 ? 'Back' : 'Refresh'}
      handleClick={is404 ? () => history.goBack() : () => reload()}
      to="/blog"
      countDown={is404}
    />
  </div>
)

Error.propTypes = {
  msg: PropTypes.string,
  code: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  is404: PropTypes.bool
}

Error.defaultProps = {
  msg: 'Unknow error!',
  is404: false
}

export default withRouter(Error)

import React from 'react'
import PropTypes from 'prop-types'

import { WhiteLink } from '../../components/StyledLinks'

// manually add active style when the route contains the root page address
// for example: show active style on Blog when url is blog/something
const NavLink = ({ exact, to, closeNav, ...restProps }) => (
  <WhiteLink
    {...restProps}
    exact={exact}
    to={to}
    activeStyle={{ borderBottom: '0.1em solid white' }}
    onClick={() => closeNav()}
  />
)

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool
}

NavLink.defaultProps = {
  exact: false
}
NavLink.displayName = 'NavLink'

export default NavLink

// give url of cloudinary, change image size based on screen size
import getViewWidth from './getViewWidth'

const responseImgResize = (src, withWidth = 0, ratio = 0.75) => {
  return src.replace(
    /\/upload\/([\S]*)h_([\d]+),w_([\d]+)\//i,
    (m, p1, p2, p3) => {
      const viewWidth = getViewWidth()
      let width
      if (withWidth) {
        width = withWidth
      } else if (viewWidth < 600) {
        width = 600
      } else {
        width = 1200
      }
      const height = Math.round(width * ratio)
      return `/upload/${p1}h_${height},w_${width}/`
    }
  )
}

export default responseImgResize

import styled, { keyframes } from 'styled-components'

const hoverSmall = keyframes`
from {
  transform: translateY(0%) rotate(135deg);
}
to {
  transform: translateY(20%) rotate(135deg);
}
`
const Chevron = styled.span`
  position: fixed;
  width: 1em;
  height: 1em;
  border-top: 0.4rem solid white;
  border-right: 0.4rem solid white;
  left: 50vw;
  margin-left: -0.5em;
  transform: rotate(135deg);
  width: 6vw;
  height: 6vw;
  animation: ${hoverSmall} 1.5s 1s alternate infinite ease-in-out;
  z-index: 10;
  @media (min-width: 600px), (orientation: landscape) {
    display: none;
  }
`
export default Chevron

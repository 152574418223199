import styled from 'styled-components'

const Tab = styled.button`
  border: 0;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 5px 5px 0 0;
  background-color: white;
  padding: 10px;
  margin-right: 5px;
  display: inline-block;
  outline: none;
  width: 80px;
  cursor: pointer;

  ${props => {
    if (props.isActive) {
      return `background-color: #70C1B3;
              color: white;
              box-shadow: 1px 2px 5px grey;`
    }
  }};
`

const TabContent = styled.div`
  padding: 10px 10px;
  border: 1px solid black;
`

export { Tab, TabContent }

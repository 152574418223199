import React from 'react'
import P from 'prop-types'
import styled from 'styled-components'

const NavButtonWrapper = styled.button`
  ${props =>
    props.isBlock ? 'display: block; clear: both;' : 'display: inline-block;'};
  max-width: 200px;
  outline: none;
  padding: 5px 5px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  transition: all 60ms ease-in-out;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #afafaf;
  border-radius: 4px;
  align-items: center;
  transition: all 200ms ease;
  background-color: white;
  min-width: 60px;
  font-size: 13px;

  &:hover {
    background-color: #da3f3d;
    border-color: #da3f3d;
    color: white;
    transition: all 200ms ease;
  }

  &:active {
    transition: all 60ms ease;
    opacity: 0.75;
  }

  a:visited {
    color: black;
  }
`

class NavButton extends React.PureComponent {
  state = { time: 9 }

  componentDidMount() {
    if (this.props.countDown) {
      this.countDown()
    }
  }

  componentDidUpdate() {
    if (this.props.countDown && this.state.time <= 0) this.props.handleClick()
  }

  countDown = () => {
    const timer = setInterval(() => {
      this.state.time > 0
        ? this.setState(({ time }) => {
            return { time: time - 1 }
          })
        : clearInterval(timer)
    }, 1000)
  }

  render() {
    const { msg, handleClick, countDown, isBlock } = this.props
    return (
      <NavButtonWrapper onClick={() => handleClick()} isBlock={isBlock}>
        {countDown ? `${msg} in ${this.state.time}s` : `${msg}`}
      </NavButtonWrapper>
    )
  }
}

NavButton.propTypes = {
  msg: P.string.isRequired,
  handleClick: P.func.isRequired,
  countDown: P.bool,
  isBlock: P.bool
}

NavButton.defaultProps = {
  countDown: false,
  isBlock: false
}

export default NavButton

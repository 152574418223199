import React from 'react'

import Modal from './Modal'
import SharedTitle from '../Utils/SharedTitle'
import ContentBlock from '../../../../../components/ContentBlock'

const ModalDemo = () => (
  <div>
    <SharedTitle />
    <ContentBlock>A modal will show after clicking the button.</ContentBlock>
    <hr />
    <Modal />
  </div>
)

export default ModalDemo

import React from 'react'

import GqlTable from './GqlTable'
import SharedTitle from '../Utils/SharedTitle'
import ContentBlock from '../../../../../components/ContentBlock'

const ModalDemo = () => (
  <div>
    <SharedTitle />
    <ContentBlock>
      This is a simple async component demonstrates GraphQL. The list of books
      are loaded asynchronously from the GraphQL server. The initial loading
      takes 2 seconds (hard-coded 2 seconds sleep on the backend). The blog page
      of this site is also built with GraphQL.
    </ContentBlock>
    <hr />
    <GqlTable />
  </div>
)

export default ModalDemo

import React, { useState } from 'react'

import { HBar, HBar1, HBar3, NavWrapper } from './style'

const BurgerMenu = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <NavWrapper onClick={() => setIsNavOpen(!isNavOpen)}>
      <HBar1 isNavOpen={isNavOpen} />
      <HBar
        style={{
          opacity: isNavOpen ? 0 : 1,
          transition: 'opacity 0.25s ease-in-out'
        }}
      />
      <HBar3 isNavOpen={isNavOpen} />
    </NavWrapper>
  )
}

export default BurgerMenu

import styled from 'styled-components'

const NavUl = styled.ul`
  display: table;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-aligh: center;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  // a bit hacking to simulate border collapse
  border-top: 1px solid grey;
`

const NavLi = styled.li`
  flex: 1 1 20%;
  border-bottom: 1px solid grey;
  white-space: nowrap;

  a {
    display: block;
    color: black;
    font-weight: bold;
    text-align: center;
    padding: 14px 10px;
    text-decoration: none;
    transition: background-color 0.2s, color 0.2s;
  }

  a:link {
    color: black;
  }

  a:hover {
    background-color: #da3f3d;
    color: white;
    transition: background-color 0.5s, color 0.5s;
  }

  a:visited {
    color: black;
  }

  a:visited:hover {
    color: white;
  }

  @media (max-width: 800px) {
    float: left;
  }
`

export { NavUl, NavLi }

// modules/NavLink.js
import React from 'react'
import { NavLink } from 'react-router-dom'

export default class MyNav extends React.Component {
  render() {
    const { Index, ...otherProps } = this.props
    return Index ? (
      <NavLink
        {...otherProps}
        exact
        activeStyle={{ backgroundColor: '#da3f3d', color: 'white' }}
      />
    ) : (
      <NavLink
        {...this.props}
        activeStyle={{ backgroundColor: '#da3f3d', color: 'white' }}
      />
    )
  }
}

import React from 'react'
import Toggle from './Toggle'
import SharedTitle from '../Utils/SharedTitle'
import ContentBlock from '../../../../../components/ContentBlock'

const ToggleDemo = () => (
  <div>
    <SharedTitle />
    <ContentBlock>
      Try the toggle which controls the status and animation. The block itself
      is also clickable. The animation is created using React-Motion.
    </ContentBlock>
    <hr />
    <Toggle />
  </div>
)

export default ToggleDemo

import styled from 'styled-components'

import Button from '../Utils/Button'

const TextArea = styled.textarea`
  width: 200px;
  height: 100px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;

  :focus {
    outline: none;
    border-color: #66ccff;
  }
`

const Warning = styled.div`
  display: block;
  width: 400px;
  color: red;
  font-size: 80%;
`

const Input = styled(Button.withComponent('input'))`
  :not([type='submit']) {
    width: 150px;
    margin: 8px 10px;
    padding: 5px;
    border: none;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
  }

  :not([type='submit']):focus {
    outline: none;
    border-bottom: 1px solid #66ccff;
    box-sizing: border-box;
  }
`

const Label = styled.label`
  display: block;
`

export { TextArea, Warning, Input, Label }

import styled from 'styled-components'

const ButtonWrapper = styled.button`
  width: 32%;
  cursor: pointer;
  padding: 0;
  max-width: 200px;
  min-height: 25px;
  background-color: ${props => props.bg};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  border: none;

  &:focus {
    outline: none;
  }
  // only animate on desktop/tablet devices
  @media (min-width: 600px), (orientation: landscape) {
    span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
    }
    span:after {
      content: '\\00bb';
      position: absolute;
      opacity: 0;
      top: 0;
      right: -10px;
      transition: 0.5s;
    }

    &:hover span {
      padding-right: 15px;
    }

    &:hover span:after {
      opacity: 1;
      right: 0;
    }
  }
`

const ImgWrapper = styled.img`
  width: 18%;
  margin-right: 3px;
`

const TextWrapper = styled.span`
  color: white;
  font-weight: bold;
  font-size: 3.5vw;

  @media (min-width: 600px), (orientation: landscape) {
    font-size: 1.2vw;
  }

  // set max font size
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`
export { ButtonWrapper, ImgWrapper, TextWrapper }

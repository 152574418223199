import styled from 'styled-components'

const AppWrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  main:after {
    content: ' ';
    display: block;
    clear: both;
  }
`

export default AppWrapper

// Scroll to top when history.location changes
import React from 'react'
import P from 'prop-types'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.PureComponent {
  static propTypes = {
    location: P.object.isRequired,
    children: P.node.isRequired
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)

import styled from 'styled-components'

export default styled.table`
  border: 1px solid #333;
  td {
    border: 1px solid #333;
    padding: 5px;
  }
  thead {
    padding: 5px;
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import { HBar, HBar1, HBar3, NavWrapper } from './style'

const BurgerMenu = ({ handleClick, isNavOpen }) => (
  <NavWrapper onClick={handleClick} role="button">
    <HBar1 isNavOpen={isNavOpen} />
    <HBar
      style={{
        opacity: isNavOpen ? 0 : 1,
        transition: 'opacity 0.25s ease-in-out'
      }}
    />
    <HBar3 isNavOpen={isNavOpen} />
  </NavWrapper>
)

BurgerMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isNavOpen: PropTypes.bool.isRequired
}

BurgerMenu.displayName = 'BurgerMenu'

export default BurgerMenu

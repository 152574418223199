import styled from 'styled-components'

const HeaderWrapper = styled.div`
  text-align: center;
  background-color: #da3f3d;
  height: 100px;
  color: white;
  flex: 0 1 100%;

  h2 {
    display: inline-block;
    margin: 0 10px;
  }
`

const Logo = styled.img`
  height: 100px;
  vertical-align: middle;
`

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  z-index: 100;
`

const ComponentWrapper = styled.div`
  display: inline-block;
  margin: 0;
  padding: 15px 0;
  @media (min-width: 800px) {
    width: 70vw;
  }
`

export { HeaderWrapper, Logo, Container, ComponentWrapper }

import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Table from './style'

const GqlTable = () => {
  const { error, loading, data } = useQuery(
    gql`
      {
        books {
          title
          author
        }
      }
    `
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  const { books } = data
  return (
    <Table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Author</th>
        </tr>
      </thead>
      <tbody>
        {books.map(book => (
          <tr key={book.title}>
            <td>{book.title}</td>
            <td>{book.author}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default GqlTable

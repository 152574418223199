import styled, { keyframes } from 'styled-components'
import P from 'prop-types'

const slideUp = keyframes`
from {
    transform: translateY(20px);
    opacity: 0}
  to {
    transform: translateY(0);
    opacity: 1;}
`

const BlockEntry = styled.div`
  color: black;
  margin: 0;
  margin-bottom: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  animation: ${slideUp} 0.5s ease-in-out;
  animation-fill-mode: backwards;
  animation-delay: ${props => `${props.index / 6}s`};

  &:hover {
    transform: none;
  }

  @media (min-width: 600px) {
    &:hover {
      transform: translateZ(10px);
      box-shadow: 0 5px 10px #ccc;
    }
  }
`

BlockEntry.propTypes = {
  index: P.number.isRequired
}

const EntryWrapper = styled.div`
  padding: 10px;
`
const EntryTitle = styled.p`
  margin: 0;
  font-family: 'Lato', Helvetica, sans-serif;
  font-size: 1.4em;
  clear: right;
`

const Time = styled.p`
  font-size: 13px;
  font-weight: lighter;
  color: grey;
`

const MoreButton = styled.a`
  :visited {
    color: #007acc;
  }

  :visited:hover {
    color: #da3f3d;
  }
`

const EntryContent = styled.div`
  font-size: 15px;
  font-weight: lighter;
`

const EntryFeaturedImg = styled.img`
  width: 100%;
  margin-bottom: 10px;
`

export {
  BlockEntry,
  EntryTitle,
  EntryWrapper,
  Time,
  MoreButton,
  EntryFeaturedImg
}

export default EntryContent

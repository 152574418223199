import PropTypes from 'prop-types'
import styled from 'styled-components'

import ContentBlock from '../ContentBlock'

const ResumeSection = styled.main``

const Subtitle = styled.p`
  margin: 0;
  ${props => !props.withNormalSize && 'font-size: 1.1em'};
  font-family: 'Lato', Helvetica, sans-serif;
  font-weight: bold;
  clear: both;
`

const Time = styled.p`
  font-size: 13px;
  font-weight: lighter;
  color: grey;
`

const ResumeContent = styled(ContentBlock)`
  margin-bottom: 1em;
`

// manually set the margin for list items
const ContentList = styled.ul`
  padding: 0;
  margin: 0;
  li {
    min-width: 20px;
    ${props => (props.isList ? 'margin: 0 15px;' : 'margin-left: 15px')};
    display: inline-block;
  }
  > li:before {
    content: '';
    display: list-item;
    position: absolute;
  }
`

ContentList.propTypes = {
  isList: PropTypes.bool
}

ContentList.defaultProps = {
  isList: false
}

const FooterWrapper = styled.footer`
  font-family: Oswald, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: lighter;
  color: #99a2aa;
  padding-top: 10px;
  hr {
    // change hr color
    background-color: #99a2aa;
    height: 1px;
    border: 0;
    margin: 0;
  }
`

export {
  ResumeSection,
  Subtitle,
  Time,
  ResumeContent,
  ContentList,
  FooterWrapper
}

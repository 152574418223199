import styled from 'styled-components'

import NavLink from './NavLink'

const MenuWrapper = styled.nav`
  display: flex;
  z-index: 5;
  background-color: ${props => (props.willLoad ? '#da3f3d' : 'transparent')};
  opacity: ${props => (props.willLoad ? 0.9 : 0)};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  position: fixed;
  height: ${props => `${props.viewHeight * 0.12}px` || '12vh'};
  transition: opacity 0.3s ease-out;

  @media (min-width: 600px), (orientation: landscape) {
    // prevent fade in on desktop site
    animation: none;
    background-color: transparent;
    opacity: 1;
    height: ${props => props.isNavOpen && `${props.viewHeight}px`};
    width: 35vw;
  }
`

const Menu = styled.ul`
  display: flex;
  align-items: center;
  transition: opacity 0.25s ease-in-out;
  padding: 0;
  list-style: none;

  @media (min-width: 600px), (orientation: landscape) {
    display: ${props => (props.isNavOpen ? 'block' : 'none')};
  }
`

const MenuItem = styled.li`
  font-size: 1.3em;
  display: block;
  flex: 0 1 auto;
  width: 30vw;
  font-family: 'Lato', Helvetica, sans-serif;
  margin: 2em 0;
  text-align: center;
  text-shadow: 1px 1px 5px #666;
  text-transform: uppercase;
  @media (min-width: 600px), (orientation: landscape) {
    font-size: 3em;
  }
`

const StrikeOutOnHover = styled(NavLink)`
  position: relative;
  color: white;
  cursor: pointer;

  :before,
  :after {
    content: '';
    position: absolute;
    width: 0%;
    height: 0.1em;
    top: 100%;
    background: #fff;
  }

  :before {
    left: 0;
  }

  :after {
    right: 0;
    background: #fff;
    transition: width 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  :hover:before {
    background: #fff;
    width: 100%;
    transition: width 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  :hover:after {
    background: transparent;
    width: 100%;
    transition: 0s;
  }
`
export { MenuWrapper, Menu, MenuItem, StrikeOutOnHover }

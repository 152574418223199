import React from 'react'
import PropTypes from 'prop-types'
import jump from 'jump.js'

import Curtain from './Curtain'
import Wrapper from './Wrapper'
import Chevron from './Chevron'
import Filter from './Filter'
import IntroPanel from './IntroPanel'

export class Introduction extends React.PureComponent {
  static propTypes = {
    viewHeight: PropTypes.number,
    isNavOpen: PropTypes.bool.isRequired
  }

  handleClick = () => {
    jump(this.props.viewHeight * 0.88, { duration: 800 })
  }

  render() {
    return (
      // this filter css rule should be put in the Wrapper
      // but firefox has a bug when fixed and filter are used together
      // separating the filter from wrapper works for now
      <Wrapper>
        <Filter
          isNavOpen={this.props.isNavOpen}
          style={{ height: this.props.viewHeight || '100vh' }}
        >
          <Curtain style={{ height: this.props.viewHeight || '100vh' }} />
          <IntroPanel />
          <Chevron
            onClick={this.handleClick}
            style={{ top: this.props.viewHeight * 0.9 || '90vh' }}
          />
        </Filter>
      </Wrapper>
    )
  }
}

export default Introduction

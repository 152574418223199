import gql from 'graphql-tag'

export const GET_POSTS = gql`
  query Posts($offset: Int!, $limit: Int!) {
    posts(offset: $offset, limit: $limit) {
      id
      title
      tags
      time
      excerpt
      featureImg
      isPublished
    }
  }
`

import React from 'react'

import { FooterWrapper } from './style'

const Footer = () => {
  const today = new Date()
  const year = today.getFullYear()
  return (
    <FooterWrapper>
      <hr />
      Copyright &copy; 2016-{year} by Keyi Ni and designed with &hearts;
    </FooterWrapper>
  )
}

export default Footer

import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0; }
  to {
    opacity: 1; }
`

const LeftPanelWrapper = styled.div`
  opacity: 0;
  box-sizing: border-box;
  width: 100vw;
  min-height: ${props =>
    props.viewHeight ? `${props.viewHeight * 0.88}px` : '88vh'};
  padding: 5vw;
  background: #fff;
  line-height: 150%;
  z-index: 2;
  position: ${props => (props.isHome ? 'relative' : 'absolute')};
  top: ${props => {
    const portion = props.isHome ? 1 : 0.12
    return props.viewHeight
      ? `${portion * props.viewHeight}px`
      : `${portion * 100}vh`
  }};
  box-shadow: 0 -5px 15px -2px rgba(0, 0, 0, 0.25);
  animation: ${fadeIn} 1s ease both;
  animation-delay: 0s;

  p {
    margin: 0;
  }

  @media (min-width: 600px), (orientation: landscape) {
    padding: 4vw;
    padding-top: 30px;
    animation-delay: 1.2s;
    box-sizing: border-box;
    width: 65%;
    min-height: ${props =>
      props.viewHeight ? `${props.viewHeight}px` : '100vh'};
    top: 0;
    left: 35%;
    // in case horizontal scroll bar appears
    overflow-x: hidden;
    box-shadow: none;
    // show box shadow
    z-index: auto;
  }

  @media (min-width: 961px), (orientation: landscape) {
    padding-right: 20vw;
  }
`

export default LeftPanelWrapper

import styled from 'styled-components'

const Title = styled.h2`
  margin: 0 0 10px;
  font-size: 1.8em;
  line-height: 1.1;
  font-family: Oswald, Helvetica, sans-serif;
`

export default Title

import React from 'react'
import { useHistory } from 'react-router-dom'

import EntryContent, {
  BlockEntry,
  EntryTitle,
  EntryWrapper,
  Time
} from '../../components/ContentBlock'
import { moreLink } from '../../components/StyledLinks'

import Title from '../../components/Title'

const ProjectsPage = () => {
  const { push } = useHistory()
  return (
    <main>
      <Title>Projects</Title>
      <hr />
      {/* To mimic the behavior of routing of pages in site,
         wrap the <div /> tag in <a />.
       */}
      <a href="https://covid.nioh.ca/" target="blank_" style={{ all: 'unset' }}>
        <BlockEntry index={0}>
          <EntryWrapper>
            <EntryTitle>COVID-19 Tracker</EntryTitle>
            <Time>2019.05 - 2019.06</Time>
            <EntryContent>
              A simple Svelte app that plots COVID-19 cases in different
              countries. It saves UI states to local storage to persist selected
              countries.
            </EntryContent>
            {moreLink('a')({
              href: 'https://covid.nioh.ca/'
            })('Read more...')}
          </EntryWrapper>
        </BlockEntry>
      </a>
      <a
        href="https://github.com/asdf123101/dockered-services"
        target="blank_"
        style={{ all: 'unset' }}
      >
        <BlockEntry index={1}>
          <EntryWrapper>
            <EntryTitle>Dockerized services</EntryTitle>
            <Time>2018.02 - 2018.03</Time>
            <EntryContent>
              A docker-compose file that quickly sets up services on my own
              server, including <code>gogs</code>, <code>nextcloud</code>,{' '}
              <code>plex</code>, and <code>aria2</code>.
            </EntryContent>
            {moreLink('a')({
              href: 'https://github.com/asdf123101/HDPG1D'
            })('Read more...')}
          </EntryWrapper>
        </BlockEntry>
      </a>
      <BlockEntry index={2} onClick={() => push('/projects/ui')}>
        <EntryWrapper>
          <EntryTitle>Common UI Components</EntryTitle>
          <Time>2017.02 - Present</Time>
          <EntryContent>
            Collection of React powered UI components, including form
            validation, modal window, carousel and more.
          </EntryContent>
          {moreLink('Link')({ to: '/projects/ui' })('Read more...')}
        </EntryWrapper>
      </BlockEntry>
      <a
        href="https://github.com/asdf123101/HDPG1D"
        target="blank_"
        style={{ all: 'unset' }}
      >
        <BlockEntry index={3}>
          <EntryWrapper>
            <EntryTitle>HDPG 1D PDE Solver</EntryTitle>
            <Time>2016.09 - 2017.02</Time>
            <EntryContent>
              Hybrydizable Discontinuous Petrov-Galerkin (HDPG) method is a
              novel finite element method that solves partial differential
              equations (PDEs) with outstanding stability and optimal
              computational resources.
            </EntryContent>
            {moreLink('a')({
              href: 'https://github.com/asdf123101/HDPG1D'
            })('Read more...')}
          </EntryWrapper>
        </BlockEntry>
      </a>
    </main>
  )
}

export default ProjectsPage

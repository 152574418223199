import styled from 'styled-components'
import Button from '../Utils/Button'

const SlideButton = styled(Button)`
  border: 2px solid black;
  border-radius: 0;
  height: 100px;
  float: left;
`

const SingleSlide = styled.div`
  width: 7vw;
  height: 100px;
  border: 2px solid black;
  text-align: center;
  float: left;
  background-color: #fff;
  ${props => {
    if (props.index === 2) {
      return `background-color: #70C1B3; color: white;`
    } else if (props.index === 0 || props.index === 4) {
      return `position: relative;
             z-index: -1;`
    }
  }};
  z-index: ${props => props.zIndex};

  @media (max-width: 600px) {
    width: 14vw;
  }
`

export { SlideButton, SingleSlide }

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import React from 'react'

const moreLink = type => props => content => {
  let TypedLink
  let injectedProps
  try {
    if (type === 'a') {
      TypedLink = styled.a``
      const { href } = props
      injectedProps = {
        target: '_blank',
        rel: 'noopener noreferrer',
        href
      }
    } else if (type === 'Link') {
      TypedLink = styled(Link)``
      const { to } = props
      injectedProps = { to }
    } else {
      throw new Error('Unknow link type')
    }
  } catch (err) {
    console.warn(err)
  }

  return <TypedLink {...injectedProps}>{content}</TypedLink>
}

export default moreLink

import gql from 'graphql-tag'

export const GET_POST_WITH_ID = gql`
  query Post($id: ID!) {
    post(id: $id) {
      id
      title
      tags
      time
      content
      featureImg
      isPublished
    }
  }
`

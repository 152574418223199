import styled from 'styled-components'

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-sizing: border-box;
  width: 100vw;
  @media (min-width: 600px), (orientation: landscape) {
    position: absolute;
    filter: blur(${props => (props.isNavOpen ? 8 : 0)}px)
      brightness(${props => (props.isNavOpen ? 70 : 100)}%);
    transition: filter 0.25s ease-in-out;
    width: 35vw;
  }
`

export default Filter

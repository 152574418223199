import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'

import SinglePost from '../../components/SinglePost'
import NotFound from '../NotFoundPage'
import Loader from '../../components/Loader'
import { GET_POST_WITH_ID } from './queries'

const Post = ({ match }) => {
  const id = match.params.postID
  const { loading, error, data } = useQuery(GET_POST_WITH_ID, {
    variables: { id }
  })
  if (loading) return <Loader />
  if (error) {
    if (error.graphQLErrors) {
      return <NotFound code={'404'} />
    }
    if (error.networkErrors) {
      return <NotFound code={'Network error: Failed to fetch'} />
    }
  }
  return data.post ? <SinglePost post={data.post} /> : <NotFound />
}

Post.propTypes = {
  match: PropTypes.object.isRequired
}

export default Post

import React from 'react'
import P from 'prop-types'

import ImgLoaderWrapper, { ImgWrapper } from './style'
import responsiveImgResize from '../../utils/responsiveImgResize'

class ImgLoader extends React.PureComponent {
  state = {
    isSmallLoaded: false,
    isFullLoaded: false
  }

  static propTypes = { isPreview: P.bool }

  render() {
    const { src, isPreview } = this.props
    const { isSmallLoaded, isFullLoaded } = this.state
    const withWidth = isPreview ? 500 : 0
    return (
      <ImgLoaderWrapper isLoaded={isFullLoaded} isPreview={isPreview}>
        {!isFullLoaded && (
          <ImgWrapper
            src={responsiveImgResize(src, 25)}
            onLoad={() => this.setState({ isSmallLoaded: true })}
            style={{ filter: 'blur(20px)' }}
            isLoaded={isSmallLoaded}
          />
        )}
        <ImgWrapper
          src={responsiveImgResize(src, withWidth)}
          onLoad={() => this.setState({ isFullLoaded: true })}
          isLoaded={isFullLoaded}
        />
      </ImgLoaderWrapper>
    )
  }
}

ImgLoader.defaultProps = { isPreview: false }

export default ImgLoader

import React from 'react'

import TabView from './TabView'
import SharedTitle from '../Utils/SharedTitle'
import ContentBlock from '../../../../../components/ContentBlock'

const TabViewDemo = () => (
  <div>
    <SharedTitle />
    <ContentBlock>
      The navigation bar is itself a tab view, althoug it is constructed with
      react-route to update URL. The following is a pure tab view that does not
      interfere with URL.
    </ContentBlock>
    <hr />
    <TabView />
  </div>
)

export default TabViewDemo

import { BrowserRouter } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import * as serviceWorker from './serviceWorker'
import ReactDOM from 'react-dom'

import 'sanitize.css/sanitize.css'

import App from './containers/App'
import genUrl from './utils/genURL'
import ScrollToTop from './components/ScrollToTop'

// Apollo client
const cache = new InMemoryCache()
persistCache({
  cache,
  storage: window.localStorage
})
const client = new ApolloClient({
  uri: process.env.NODE_ENV === 'production' ? genUrl('graphql') : '/',
  cache
})

// render dom
ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('app')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
    window.location.reload()
  }
})

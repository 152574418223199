// Given the posts, render each post with excerpt in a block
import React from 'react'

import PostExcerpt from './PostExcerpt'
import getViewWidth from '../../utils/getViewWidth'

const BlogPosts = ({ posts }) => {
  const width = getViewWidth()
  const postExcerpts = posts.map((post, i) => (
    <PostExcerpt key={post.id} post={post} index={i} />
  ))

  // if view width is larger than 900px, use two columns layout
  return width > 900 ? (
    <>
      <div
        style={{
          width: '49%',
          float: 'left'
        }}
        key="left"
      >
        {postExcerpts.filter((el, i) => !(i % 2))}
      </div>
      <div
        style={{
          width: '49%',
          float: 'right'
        }}
        key="right"
      >
        {postExcerpts.filter((el, i) => i % 2)}
      </div>
    </>
  ) : (
    postExcerpts
  )
}

export default BlogPosts

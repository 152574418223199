import React, { Component } from 'react'

import SharedTitle from '../Utils/SharedTitle'
import BurgerMenu from './BurgerMenu'
import ContentBlock from '../../../../../components/ContentBlock'

export default class BurgerMenuDemo extends Component {
  render() {
    return (
      <div>
        <SharedTitle />
        <ContentBlock>
          This is an animated burger menu. The implementation is fairly
          straightforward with the help of styled components and css animations.
        </ContentBlock>
        <hr />
        <BurgerMenu />
      </div>
    )
  }
}

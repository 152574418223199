import styled from 'styled-components'
import React from 'react'

import EntryContent from '../ContentBlock'
import Title from '../Title'
import Share from '../SocialButtons'

const BlogContent = styled(EntryContent)`
  font-family: Merriweather, serif;

  .underline {
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 2px;
    border-bottom: 2px solid #ccc;
    border-right: 5px solid transparent;
  }

  // img tag size
  img {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  img.center {
    width: auto;
    margin: 10px auto;
  }

  br {
    content: ' ';
    margin: 0.5em 0;
    display: block;
  }

  .katex-display {
    display: block;
    padding: 5px;
    margin: 10px 0;
    white-space: nowrap;
    max-width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #d3d3d3;
    }
  }

  .katex {
    font-size: 16px;
  }

  .ytb {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin: 10px 0;
  }

  .ytb iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  b,
  strong {
    font-weight: 700;
  }

  blockquote {
    margin: 1em 0;
    padding: 0 30px;
    border-left: 5px solid #eaeaea;
    color: #7f8fa4;
    font-size: 1.2em;
    font-style: italic;
  }

  // line number
  .line-number {
    text-align: right;
    width: 2em; // good for 3 digits
    display: inline-block;
    color: #999;
    padding: 0.11em 0.4em 0.11em 0;
    border-right: 1px solid #999;
    margin-right: 0.8em;
    word-spacing: normal;
    letter-spacing: normal;
    vertical-align: middle;
    user-select: none;
  }

  /* http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript */
  /**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

  code[class*='lang-'],
  pre[class*='lang-'] {
    color: #ccc;
    background: none;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    padding: 0;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  /* Code blocks */
  pre[class*='lang-'] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    // rounded corner
    border-radius: 5px;
  }

  :not(pre) > code[class*='lang-'],
  pre[class*='lang-'] {
    background: #2d2d2d;
  }

  /* Inline code */
  :not(pre) > code[class*='lang-'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.block-comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #999;
  }

  .token.punctuation {
    color: #ccc;
  }

  .token.tag,
  .token.attr-name,
  .token.namespace,
  .token.deleted {
    color: #e2777a;
  }

  .token.function-name {
    color: #6196cc;
  }

  .token.boolean,
  .token.number,
  .token.function {
    color: #f08d49;
  }

  .token.property,
  .token.class-name,
  .token.constant,
  .token.symbol {
    color: #f8c555;
  }

  .token.selector,
  .token.important,
  .token.atrule,
  .token.keyword,
  .token.builtin {
    color: #cc99cd;
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.regex,
  .token.variable {
    color: #7ec699;
  }

  .token.operator,
  .token.entity,
  .token.url {
    color: #67cdcc;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .token.inserted {
    color: green;
  }
`

const FeaturedImg = styled.img`
  width: 100%;
  margin: 10px 0 1.2em 0;
  box-shadow: 4px 3px 6px gray;
`

const ShareWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`

const ShareButtons = ({ id, title }) => (
  <ShareWrapper>
    <Share postID={id} title={title} type="facebook" />
    <Share postID={id} title={title} type="twitter" />
  </ShareWrapper>
)

export { FeaturedImg, Title, ShareButtons }
export default BlogContent

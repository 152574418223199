import React from 'react'
import NavLink from './NavLink'

import { NavUl, NavLi } from './style.js'

const Nav = () => (
  <NavUl>
    <NavLi>
      <NavLink Index to="/projects/ui">
        Introduction
      </NavLink>
    </NavLi>
    <NavLi>
      <NavLink to="/projects/ui/toggle">Toggle</NavLink>
    </NavLi>
    <NavLi>
      <NavLink to="/projects/ui/tabs">Tabs</NavLink>
    </NavLi>
    <NavLi>
      <NavLink to="/projects/ui/modal">Modal</NavLink>
    </NavLi>
    <NavLi>
      <NavLink to="/projects/ui/slideshow">Slide</NavLink>
    </NavLi>
    <NavLi>
      <NavLink to="/projects/ui/form">Form</NavLink>
    </NavLi>
    <NavLi>
      <NavLink to="/projects/ui/menu">Menu</NavLink>
    </NavLi>
    <NavLi>
      <NavLink to="/projects/ui/gql">GQL</NavLink>
    </NavLi>
  </NavUl>
)

export default Nav

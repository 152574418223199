import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'

import Title from '../../components/Title'
import BlogPosts from '../../components/BlogPosts'
import NavButton from '../../components/NavButton'

const BlogMain = ({ tag, history, handleFetchMore, tagedPosts }) => {
  const [shouldFetch, setShouldFetch] = useState(false)
  const eleRef = useRef(null)
  useEffect(() => {
    const onScroll = debounce(() => {
      console.log('scrolling')
      console.log(
        Math.round(window.innerHeight + document.documentElement.scrollTop),
        eleRef.current.parentElement.offsetHeight - 10
      )
      if (
        Math.round(window.innerHeight + document.documentElement.scrollTop) >=
        eleRef.current.parentElement.offsetHeight - 10
      ) {
        setShouldFetch(true)
      }
    }, 100)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (shouldFetch) {
      console.log('start fetching')
      setShouldFetch(false)
      handleFetchMore()
    }
  }, [shouldFetch])

  return (
    <main ref={eleRef}>
      <Title>{tag ? `Tag: ${tag}` : 'Blog'}</Title>
      <hr />
      {/* Show message when tag query is given. */}
      {/* If no post is found, also show a not found message */}
      {tagedPosts.length === 0 ? (
        <div
          style={{ margin: '10px 0' }}
        >{`Sorry, no post is taged with ${tag}.`}</div>
      ) : (
        <BlogPosts posts={tagedPosts} />
      )}
      {tag && (
        <NavButton
          msg="Back to Blog"
          handleClick={() => history.push('/blog')}
          isBlock
        />
      )}
    </main>
  )
}

BlogMain.propTypes = {
  tag: PropTypes.string,
  history: PropTypes.object.isRequired,
  handleFetchMore: PropTypes.func.isRequired,
  tagedPosts: PropTypes.array.isRequired
}

export default BlogMain

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export const TagWrapper = styled(Link)`
  min-width: 30px;
  font-size: 13px;
  font-weight: 300;
  color: grey;
  font-style: italic;
  float: left;
  text-align: center;
  margin: 0 5px 0 0;
  cursor: pointer;

  &:link,
  &:visited {
    color: grey;
  }

  &:hover {
    color: #da3f3d;
  }

  &:visited:hover {
    color: #da3f3d;
  }
`

const Tag = ({ children, float }) => {
  return (
    <TagWrapper
      style={{ float }}
      to={`/blog?tag=${children}`}
    >{`<${children} />`}</TagWrapper>
  )
}

Tag.propTypes = {
  float: (props, propName, componentName) => {
    if (!/[right,left]/.test(props[propName])) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to' +
          ' `' +
          componentName +
          '`. Validation failed.'
      )
    }
  },
  children: PropTypes.string.isRequired
}

export default Tag

import styled from 'styled-components'
import React from 'react'

import logo from './images/logo-white.png'
import github from './images/github.svg'
import linkedin from './images/linkedin.svg'
import email from './images/email.svg'

const IntroductionWrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  text-align: center;
  color: white;
  font-family: 'Lato', Helvetica, sans-serif;
  font-size: 6vw;
  z-index: 10;

  & > img {
    width: 40%;
  }

  p:first-of-type {
    font-size: 150%;
  }

  p {
    width: 100%;
    margin: 5% auto;
  }

  img {
    margin: 0 5px;
  }

  @media (max-width: 321px) {
    font-size: 16px;
  }

  @media (min-width: 600px), (orientation: landscape) {
    position: fixed;
    box-sizing: border-box;
    width: 35vw;
    float: left;
    font-size: 2.5vw;

    p {
      width: 90%;
    }
  }

  @media (min-width: 1024px) {
    font-size: 28px;
    & > img {
      width: 240px;
    }
  }
`

const IntroPanel = () => (
  <IntroductionWrapper>
    <img src={logo} alt="nioh-logo" />
    <p>I&apos;m Keyi Ni</p>
    <p>an explorer and developer</p>
    <p className="contact">
      <a
        href="https://github.com/asdf123101"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={github} alt="github" />
      </a>
      <a
        href="https://www.linkedin.com/in/keyi-ni/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={linkedin} alt="linkedin" />
      </a>
      <a href="mailto:keyi@nioh.ca">
        <img src={email} alt="email" />
      </a>
    </p>
  </IntroductionWrapper>
)

IntroPanel.displayName = 'IntroPanel'

export default IntroPanel

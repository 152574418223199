import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'

import Tags from '../Tags'
import BlogContent, { Title, ShareButtons } from './style'
import { Time } from '../ContentBlock/index'
import genURL from '../../utils/genURL'
import ImgLoader from '../ImgLoader/'

const SinglePost = ({ post }) => {
  // loaded, change title
  const history = useHistory()
  const { postTitle, postID } = useParams()
  const title = post.title.replace(/\s+/g, '-').toLowerCase()
  useEffect(() => {
    if (title !== postTitle) {
      const redirectRoute = `/blog/${postID}/${title}`
      history.replace(redirectRoute)
    }
  }, [postTitle])

  return (
    <main>
      <Helmet>
        <meta property="og:title" content={post.title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${genURL(post.id)}`} />
        {post.featureImg && (
          <meta
            property="og:image"
            content={post.featureImg.replace('https', 'http')}
          />
        )}
        {post.featureImg && (
          <meta property="og:image:secure_url" content={post.featureImg} />
        )}
        <meta property="og:description" content={post.excerpt} />
        <meta property="fb:app_id" content="1001595816649074" />
        <title>{post.title}</title>
      </Helmet>
      {post.featureImg && <ImgLoader src={post.featureImg} />}
      <Title style={{ marginTop: '10px' }}>{post.title.toUpperCase()}</Title>
      <Time style={{ float: 'left' }}>
        {new Date(post.time).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })}
      </Time>
      {Tags(post.tags, 'right')}
      <hr style={{ clear: 'both' }} />
      <BlogContent dangerouslySetInnerHTML={{ __html: post.content }} />
      <hr />
      <ShareButtons id={post.id} title={post.title} />
    </main>
  )
}

SinglePost.propTypes = {
  post: PropTypes.object
}

export default SinglePost

import React from 'react'

import Footer from './Footer'
import {
  ResumeSection,
  Subtitle,
  Time,
  ResumeContent,
  ContentList
} from './style'

import Title from '../Title'

import closeNavAfterLoaded from '../../utils/closeNavAfterLoaded'

export const Resume = () => (
  <ResumeSection>
    <Title>Work Experience</Title>
    <hr />
    <Subtitle>Senior Full-Stack Engineer - Genesys</Subtitle>
    <Time>2023.07 - 2024.05</Time>
    <ResumeContent>
      <ContentList>
        <li>
          Recognition Service: built this new service to allow agents to send
          and view recognitions, owning both the front-end and back-end
          components
        </li>
      </ContentList>
    </ResumeContent>
    <Subtitle>Software Developer II - Amazon Web Service</Subtitle>
    <Time>2021.07 - 2023.07</Time>
    <ResumeContent>
      <ContentList>
        <li>
          Identity Center Single Sign-On Instance Service: owner of this new
          tier 2 metadata service from design to fully launched, including
          design, development, testing, validation, and on-call operations
        </li>
        <li>
          AWS Identity Center Region Build Automation: automated region build
          tasks and led the initiative of developing a one-click region build
          procedure
        </li>
      </ContentList>
    </ResumeContent>
    <Subtitle>Senior Software Developer - NetBrain</Subtitle>
    <Time>2019.08 - 2021.07</Time>
    <ResumeContent>
      <ContentList>
        <li>
          Decision Tree: config and visualize network incident debugging work
          flow
        </li>
        <li>
          Layout Algorithms Rework: extracted the networking device layout
          algorithms from the front-end repository to a common library, rewrote
          the libray in C++, and delivered the library with WASM, achieving up
          to 10 times speed boost on most computationally demanding tasks
        </li>
        <li>
          Layout feature development, including edge grouping, cross-reducing
          algorithms, and more
        </li>
      </ContentList>
    </ResumeContent>
    <Subtitle>Software Developer - Genesys</Subtitle>
    <Time>2018.07 - 2019.08</Time>
    <ResumeContent>
      <ContentList>
        <li>
          PR Buddy: a bot that manages pull requests by automatically leaving a
          comment that highlights various status of the pull request and
          intelligently promting participants of the pull request to take action
        </li>
        <li>
          Swagger Linter: a Java package that enforces various coding
          conventions and rules in Genesys Public API
        </li>
        <li>
          Migration Monitor: a node application that detects and publishes
          breaking changes between major versions of Genesys Workspace Web
          Edition based on the difference of configuration files
        </li>
      </ContentList>
    </ResumeContent>
    <Subtitle>Automated PDE solver</Subtitle>
    <Time>2017.02 - 2018.06</Time>
    <ResumeContent>
      <ContentList>
        <li>
          Implemented hybridizable discontinuous Petrov-Galerkin (HDPG) and
          benchmarked its performance in terms of solution stability and CPU
          time
        </li>
        <li>
          Developed a stabilization strategy based on progressive HDPG and
          adaptive local artificial viscosity (PAV-HDPG) that efficiently
          resolves discontinuities and demonstrated it is a convergent scheme by
          solving the Euler equations and inviscid and viscous Burgers'
          equations
        </li>
        <li>
          Implemented a convergence acceleration mesh adaptation algorithm based
          on error estimates of PAV-HDPG scheme
        </li>
      </ContentList>
    </ResumeContent>
    <Subtitle>Nioh</Subtitle>
    <Time>2017.04 - 2017.09</Time>
    <ResumeContent>
      <ContentList>
        <li>
          Independently designed and implemented nearly all the UI components on
          the website with front-end library React and state management library
          Redux
        </li>
        <li>
          Applied modern web design features, such as progressive image loading,
          JavaScript asynchronous resource requesting API <code>fetch</code>,
          and modern CSS like <code>filter</code>
        </li>
        <li>
          Developed a simple but efficient content management system for
          managing blog posts on Node.js
        </li>
        <li>
          90% test coverage with over 50 test suites and 400 unit tests,
          covering UI interactions, error handling, and XHR
        </li>
        <li>
          Implemented responsive design and reduced dependence on external
          resources, resulting in fast loading speed with average load time
          under 2 seconds on normal 4G network
        </li>
        <li>
          Check out{' '}
          <a href="https://nioh.ca/blog?tag=making-nioh">Making Nioh series</a>{' '}
          for more technical details
        </li>
      </ContentList>
    </ResumeContent>
    <Subtitle>Web server deployment and maintainance</Subtitle>
    <Time>2016.09 - Present</Time>
    <ResumeContent>
      <ContentList>
        <li>
          Maintain a private web server and various services on it, including
          Gogs, Nextcloud, Jenkins continuous integration, and dnsmasq
        </li>
        <li>
          Host staging version of Nioh and facilitate the website’s performace
          tests and UX tests
        </li>
        <li>
          Dockerized all the services, see{' '}
          <a
            href="https://github.com/asdf123101/dockered-services"
            target="_blank"
            rel="noopener noreferrer"
          >
            the sample docker-compose file
          </a>
          .
        </li>
      </ContentList>
    </ResumeContent>
    <Subtitle>
      <a
        href="https://github.com/asdf123101/HDPG1D"
        target="_blank"
        rel="noopener noreferrer"
      >
        Adaptive 1D finite element solver
      </a>
    </Subtitle>
    <Time>2016.09 - 2017.04</Time>
    <ResumeContent>
      <ContentList>
        <li>
          Developed an autmomated one-dimensional PDE solver based on HDPG
          discretization in Python
        </li>
        <li>
          Designed a complete solution procedure, including a command line
          interface that establishes equation and invokes HDPG discretization,
          mesh adaptation based on dual-weighted residual, and solution and
          convergence visualization
        </li>
      </ContentList>
    </ResumeContent>
    <Title>Software skills</Title>
    <hr />
    <Subtitle withNormalSize>Advanced</Subtitle>
    <ResumeContent>
      <ContentList isList>
        <li>React.js</li>
        <li>Redux</li>
        <li>HTML + CSS + Javascript</li>
        <li>MATLAB</li>
        <li>Git</li>
        <li>LATEX</li>
        <li>Linux</li>
        <li>MongoDB</li>
      </ContentList>
    </ResumeContent>
    <Subtitle withNormalSize>Intermediate</Subtitle>
    <ResumeContent>
      <ContentList isList>
        <li>C++</li>
        <li>Python</li>
        <li>Bash</li>
        <li>Docker</li>
        <li>CMake</li>
        <li>Node.js</li>
        <li>Express</li>
        <li>Redux-Saga</li>
        <li>MySQL</li>
        <li>JAVA</li>
      </ContentList>
    </ResumeContent>
    <Footer />
  </ResumeSection>
)

export default closeNavAfterLoaded(Resume)

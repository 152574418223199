import styled from 'styled-components'

const width = 200
const heightInPercent = 0.15
const translateHeight = (width * (1 + heightInPercent)) / 4

const HBar = styled.div`
  width: ${width}px;
  height: ${width * heightInPercent}px;
  background-color: white;
  margin: ${(width * (1 - heightInPercent * 3)) / 4}px auto;
`

const HBar1 = styled(HBar)`
  transform: ${props =>
    `translateY(${props.isNavOpen ? translateHeight : 0}px)
     rotate(${props.isNavOpen ? 45 : 0}deg)`};
  transition: transform 0.25s ease-in-out;
`

const HBar3 = styled(HBar)`
  transform: ${props =>
    `translateY(${props.isNavOpen ? -translateHeight : 0}px)
     rotate(${props.isNavOpen ? -45 : 0}deg)`};
  transition: transform 0.25s ease-in-out;
`

const NavWrapper = styled.div`
  display: block;
  padding: 20px;
  width: ${width + 40}px;
  height: ${width + 40}px;
  background-color: #70c1b3;
  text-align: center;
`

export { HBar, HBar1, HBar3, NavWrapper }

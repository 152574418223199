import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Tab, TabContent } from './style'

const data = {
  tabs: ['One', 'Two', 'Three'],
  content: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'Maecenas sollicitudin fringilla lacus sagittis tincidunt. Donec efficitur mollis tempor. Cras laoreet in nunc nec imperdiet. Ut sed massa efficitur, suscipit nisi eu, vehicula lectus. Suspendisse imperdiet pretium libero eget mattis. Vestibulum laoreet ante tortor. Vivamus vel tortor pulvinar, auctor libero ac, consectetur erat. Aliquam semper tempor libero, sit amet mattis orci congue vitae. Vivamus pretium mi vitae dolor scelerisque, eget gravida sem varius.',
    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.'
  ]
}

const ContentView = ({ content }) => <TabContent>{content}</TabContent>

const TabGroup = ({ tabs, handleClick, isActive }) => {
  return (
    <div>
      {tabs.map((tabName, id) => (
        <Tab
          onClick={() => handleClick(id)}
          isActive={isActive(id)}
          key={tabName}
        >
          {tabName}
        </Tab>
      ))}
    </div>
  )
}

TabGroup.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.func.isRequired
}
TabGroup.displayName = 'TabGroup'

const TabView = ({ tabs = data.tabs, tabContent = data.content }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div>
      <TabGroup
        tabs={tabs}
        handleClick={id => setActiveIndex(id)}
        isActive={id => id === activeIndex}
      />
      <ContentView content={tabContent[activeIndex]} />
    </div>
  )
}

TabView.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  tabContent: PropTypes.arrayOf(PropTypes.string)
}
export default TabView

import React from 'react'
import P from 'prop-types'

import genURL from '../../utils/genURL'
import facebook from './facebook.svg'
import google from './google.svg'
import twitter from './twitter.svg'
import { ButtonWrapper, ImgWrapper, TextWrapper } from './style'

const popupwindow = (url, title, w, h) => {
  const top = window.top.outerHeight / 2 + window.top.screenY - h / 2
  const left = window.top.outerWidth / 2 + window.top.screenX - w / 2
  return window.open(
    url,
    title,
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,
     width=${w}, height=${h}, top=${top}, left=${left}`
  )
}

const Img = ({ type }) => {
  let iconType
  if (type === 'facebook') iconType = facebook
  if (type === 'google') iconType = google
  if (type === 'twitter') iconType = twitter
  return <ImgWrapper src={iconType} />
}

const Text = ({ type }) => {
  let text
  if (type === 'facebook' || type === 'google') text = 'Share'
  if (type === 'twitter') text = 'Tweet'
  return <TextWrapper>{text}</TextWrapper>
}

const Share = ({ postID, title, type }) => {
  let url
  let bg
  // construct url and background color
  if (type === 'facebook') {
    url = 'https://www.facebook.com/sharer/sharer.php?u='
    bg = '#3b5998'
  } else if (type === 'google') {
    url = 'https://plus.google.com/share?url='
    bg = '#d34836'
  } else if (type === 'twitter') {
    url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `${title}`
    )}&url=`
    bg = '#1da1f2'
  } else throw Error('Unknow social media type.')

  return (
    <ButtonWrapper
      onClick={() =>
        popupwindow(
          `${url}${encodeURIComponent(genURL(postID))}`,
          title,
          600,
          600
        )
      }
      bg={bg}
    >
      <Img type={type} />
      <Text type={type} />
    </ButtonWrapper>
  )
}

Share.propTypes = {
  postID: P.string.isRequired,
  title: P.string.isRequired,
  type: P.string.isRequired
}

export default Share

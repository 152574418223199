import React from 'react'

import SlideShow from './SlideShow'
import SharedTitle from '../Utils/SharedTitle'
import ContentBlock from '../../../../../components/ContentBlock'

const ModalDemo = () => (
  <div>
    <SharedTitle />
    <ContentBlock>
      Clicking the button will slide the blocks and the center block is always
      colored. The animation is borrowed from React-flip-move library with some
      hack. Overall doing transition animation in React is a bit hard.{' '}
    </ContentBlock>
    <hr />
    <SlideShow />
  </div>
)

ModalDemo.displayName = 'ModalDemo'
export default ModalDemo

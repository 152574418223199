import React from 'react'
import PropTypes from 'prop-types'

import BurgerMenu from '../../components/BurgerMenu'
import { MenuWrapper, Menu, MenuItem, StrikeOutOnHover } from './style'

const Nav = ({ isNavOpen, viewHeight, willLoad, toggleNav }) => (
  <MenuWrapper
    isNavOpen={isNavOpen}
    viewHeight={viewHeight}
    willLoad={willLoad}
  >
    <BurgerMenu isNavOpen={isNavOpen} handleClick={() => toggleNav()} />
    <Menu isNavOpen={isNavOpen}>
      <MenuItem>
        <StrikeOutOnHover
          to="/"
          exact
          closeNav={() => {
            toggleNav(false)
          }}
        >
          Home
        </StrikeOutOnHover>
      </MenuItem>
      <MenuItem>
        <StrikeOutOnHover
          to="/blog"
          closeNav={() => {
            toggleNav(false)
          }}
        >
          Blog
        </StrikeOutOnHover>
      </MenuItem>
      <MenuItem>
        <StrikeOutOnHover
          to="/projects"
          closeNav={() => {
            toggleNav(false)
          }}
        >
          Projects
        </StrikeOutOnHover>
      </MenuItem>
    </Menu>
  </MenuWrapper>
)

Nav.propTypes = {
  isNavOpen: PropTypes.bool.isRequired,
  viewHeight: PropTypes.number.isRequired,
  toggleNav: PropTypes.func.isRequired,
  willLoad: PropTypes.bool.isRequired
}

export default Nav

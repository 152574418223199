import { useLocation } from 'react-router-dom'

/**
 * NotFoundPage
 *
 * This is the page we handle network or http request errors.
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react'

import messages from './messages'
import Error from './Error'

const NoteFound = ({ code = '404', reload }) => {
  const location = useLocation()
  const handleReload = reload || (() => location.reload())
  if (Object.keys(messages).includes(code)) {
    return (
      <Error
        code={code}
        msg={messages[code]}
        reload={handleReload}
        is404={code === '404'}
      />
    )
  }
  return <Error code={code} reload={handleReload} />
}

export default NoteFound

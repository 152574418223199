import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import EntryContent, {
  BlockEntry,
  EntryWrapper,
  Time,
  EntryTitle
} from '../ContentBlock'
import Tags from '../Tags'
import ImgLoader from '../ImgLoader'

export const PostExcerpt = ({ post, index }) => {
  const history = useHistory()
  const title = post.title.replace(/\s+/g, '-').toLowerCase()
  return (
    <BlockEntry
      onClick={e => {
        e.target.href || history.push(`/blog/${post.id}/${title}`)
      }}
      index={index}
      role="button"
    >
      {post.featureImg && <ImgLoader src={post.featureImg} isPreview />}
      <EntryWrapper>
        <EntryTitle>{post.title}</EntryTitle>
        <Time>
          {new Date(post.time).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </Time>
        <EntryContent>{post.excerpt}</EntryContent>
        {Tags(post.tags)}
        <br />
      </EntryWrapper>
    </BlockEntry>
  )
}

PostExcerpt.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default PostExcerpt

import styled from 'styled-components'

const BG = styled.div`
  border-radius: 4px;
  background-color: grey;
  position: relative;
  margin: 5px 3px 10px;
  width: 250px;
  height: 50px;
`

const ToggleBlock = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: #70c1b3;
`

export { BG, ToggleBlock }

import React, { useContext, useEffect } from 'react'

import { AppContext } from '../containers/App/AppContext'

const closeNavAfterLoaded = Component => {
  return () => {
    const { isNavOpen, setIsNavOpen } = useContext(AppContext)
    useEffect(() => {
      if (isNavOpen) {
        setIsNavOpen(false)
      }
    }, [])
    return <Component />
  }
}

export default closeNavAfterLoaded

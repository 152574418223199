// Give tags as an array, render each tag.
import React from 'react'

import Tag from './Tag'

const Tags = (tags, float = 'left') => {
  return tags.map(tag => (
    <Tag key={tag} float={float}>
      {tag}
    </Tag>
  ))
}

export default Tags

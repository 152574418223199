import styled from 'styled-components'

const ImgLoaderWrapper = styled.div`
  width: 100%;
  padding-bottom: 75%;
  box-shadow: ${props => (props.isPreview ? null : '4px 3px 6px gray')};
  position: relative;
  overflow: hidden;
`

export const ImgWrapper = styled.img`
  position: absolute;
  opacity: ${props => (props.isLoaded ? 1 : 0)};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-out;
`

export default ImgLoaderWrapper

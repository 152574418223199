import React from 'react'
import jump from 'jump.js'
import { Route, Switch, useHistory } from 'react-router-dom'

import Nav from '../Nav'
import NavButton from '../../../../../components/NavButton'
import Home from '../Home'
import ToggleDemo from '../Toggle'
import TabViewDemo from '../Tabs'
import ModalDemo from '../Modal'
import SlideShowDemo from '../SlideShow'
import FormDemo from '../Form'
import BurgerMenuDemo from '../BurgerMenu'
import GqlDemo from '../GQL'

import { Container, ComponentWrapper } from './style'

const App = () => {
  const { push } = useHistory()
  return (
    <Container>
      <Nav />
      <ComponentWrapper>
        <Switch>
          <Route exact path="/projects/ui" component={Home} />
          <Route path="/projects/ui/toggle" component={ToggleDemo} />
          <Route path="/projects/ui/tabs" component={TabViewDemo} />
          <Route path="/projects/ui/modal" component={ModalDemo} />
          <Route path="/projects/ui/slideshow" component={SlideShowDemo} />
          <Route path="/projects/ui/form" component={FormDemo} />
          <Route path="/projects/ui/menu" component={BurgerMenuDemo} />
          <Route path="/projects/ui/gql" component={GqlDemo} />
        </Switch>
      </ComponentWrapper>
      <hr style={{ width: '100%' }} />
      <NavButton handleClick={() => push('/projects')} msg={'Projects'} />
      <NavButton handleClick={() => jump('body')} msg={'Top'} />
    </Container>
  )
}

export default App

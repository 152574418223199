import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ModalWrapper, ModalPara, ModalButton } from './style.js'
import Button from '../Utils/Button'

const ModalWindow = ({ handleClickClose, children }) => (
  <ModalWrapper>
    <ModalButton onClick={handleClickClose}>X</ModalButton>
    <ModalPara>{children}</ModalPara>
  </ModalWrapper>
)

ModalWindow.displayName = 'ModalWindow'

ModalWindow.propTypes = {
  handleClickClose: PropTypes.func.isRequired
}

const modalContentDefault = `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
  cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
  est laborum.`

export default function Modal({ modalContent = modalContentDefault }) {
  const [isActive, setIsActive] = useState(false)

  return (
    <div>
      <Button className="show" onClick={() => setIsActive(true)}>
        Show Modal
      </Button>
      {isActive && (
        <ModalWindow handleClickClose={() => setIsActive(false)}>
          {modalContent}
        </ModalWindow>
      )}
    </div>
  )
}
